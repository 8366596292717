.css-1qzevvg {
    position: relative;
    display: flex;
    height: 40px;
    margin: 1rem auto 0px;
    width: 100% !important;
    margin: 10 !important;
    -webkit-box-pack: justify;
    justify-content: space-between;
}

.css-1qzevvg img{
    background-color: rgb(250, 250, 250) !important;
    opacity: 0 !important;
    margin: 10 !important;
    margin-bottom: 20 !important;
    /* z-index: -1; */
}
.css-doq0dk {
    margin:  20;
    position: relative;
    /* width: 100%; */
    height: 60% !important;
}
/* .css-doq0dk:hover {
    box-shadow: rgb(0 0 0 / 40%)1px 3px 40px 27px;
} */
 .css-1fzpoyk{
    opacity: 1 !important; 
     
 }  
 .css-1fzpoyk:hover{
    box-shadow: rgb(0 0 0 / 40%)1px 3px 40px 27px; 
     
 } 