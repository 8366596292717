
.card {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    /* justify-content: center; */
    background-color:#cac7c7f6;
    width: 12rem;
    height: 18rem;
    padding: 0rem 0.7rem 0.7rem 0.7rem;
    border-radius: 30px;
 
  }
   /* .card :hover{
    box-shadow : rgba(0, 0, 0, 0.116)1px 3px 40px 27px;
   } */
  
  .card-big  {
    display: flex;
    flex-direction: column;
   
    background-color:#cac7c7f6;
    width: 16rem;
    height: 24rem;
    padding: 0rem 0.7rem 0.7rem 0.7rem;
    border-radius: 30px;
    margin-bottom: 20;
  }
  /* .card img{
    background: black;
  } */
  .card img {
    margin-top: 10%;
    width: 100%;
    border-radius: 20px;
    padding-bottom: 2rem;
    /* height:20rem; */
  }
  .card-big img {
    margin-top: 15%;
    width: 100%;
    border-radius: 20px;
    padding-bottom: 2rem;
    /* height:20rem; */
  }
  
  .card h2 {
    margin: 0;
    margin-top: 1rem;
  }
  
  .card p {
    margin: 0;
    margin-top: 0.5rem;
    margin-bottom: 1.5rem;
  }
  

/*   
  .card .css-1qzevvg{
    position: relative;
    display: flex;
    height: 40px;
    margin: 1rem auto 0px
px
;
    width: 80%;
    -webkit-box-pack: justify;
    justify-content: space-between;
  } */